<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" [ngStyle]="{'background': theme?.appCustomisation?.secondary_color}" (click)="navigateTo()">
<!-- <div class="user-profile" style="background: url(assets/images/background/profile-default-splash.png) no-repeat;height: 164px;" (click)="navigateTo()"> -->
    <!-- User profile image -->
    <div class="profile-img">
        <img [src]="user.attributes.profile_image">
        <!--<secured-image [src]="user.attributes.profile_image"></secured-image>-->
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text" >
        <div>
            {{ [user.attributes.first_name, user.attributes.last_name].join(' ') }}
          </div>
          <div class="user-email">
            {{ user.attributes.email }}
          </div>
          <div class="roles">
            <ng-container *ngFor="let role of user.user_roles_human">
              <div class="role-item">{{ role }}</div>
            </ng-container>
      
          </div>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings
        </button>
        <a mat-menu-item [routerLink]="'/user/myprofile/'">
            <mat-icon>account_box</mat-icon> Profile
        </a>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon> Sign Out
        </button>
    </mat-menu>
</div>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems" routerLinkActive="selected" 
      group="{{menuitem.state}}" (click)="scrollToTop()">
      <a appAccordionToggle [routerLink]="[menuitem.state]" *ngIf="menuitem.type === 'link' && menuitem.roleAccess">
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name | translate }}</span>
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink' && menuitem.roleAccess">
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name | translate }}</span>
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <a appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink' && menuitem.roleAccess">
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name | translate}}</span>
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
      <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator' && menuitem.roleAccess">
        <span>{{ menuitem.name | translate }}</span>
      </div>
    </mat-list-item>
  </mat-nav-list>
