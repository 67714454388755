import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserService } from '../../../_services/user.service';
import { AuthenticationFinalResponse } from '../../../_models/user_authentication';
import { Menu } from '../../../_models/user_authentication';
import { LocalStorageService, StorageKey } from 'src/app/_services/storage';
import { Branding } from 'src/app/_models/branding';
import { PlatformService } from 'src/app/_services/platform.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss'],
})
export class VerticalAppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  public user!: AuthenticationFinalResponse;
  public menuItems: Menu[] = [
    {
      icon: 'insights',
      name: 'Dashboard',
      type: 'link',
      state: 'manager/dashboard',
      roleAccess: this.getHideFlag(),
    },
    {
      icon: 'insights',
      name: 'Data Dashboards',
      type: 'link',
      state: 'manager/data',
      roleAccess: true,
    },
    {
      icon: 'business',
      name: 'Users',
      type: 'link',
      state: 'administration/users',
      roleAccess: this.getHideFlag(),
    },
    {
      icon: 'business',
      name: 'Groups',
      type: 'link',
      state: 'administration/groups',
      roleAccess: true,
    },
    {
      icon: 'business',
      name: 'Upload PDF Speech',
      type: 'link',
      state: 'administration/licenses',
      roleAccess: true,
    },
    {
      icon: 'business',
      name: 'Organisation',
      type: 'link',
      state: 'administration/company',
      roleAccess: this.getHideFlag(),
    },
    {
      icon: 'support',
      name: 'Support Tickets',
      type: 'link',
      state: 'support/tickets',
      roleAccess: true,
    },
  ];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public userService: UserService,
    private localStorageService: LocalStorageService,
    private platformService: PlatformService,
    private _router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.userService.currentUserDetails.subscribe(
      (data: AuthenticationFinalResponse) => {
        this.user = data;
      },
    );
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  getHideFlag(): boolean {
    const currentUser: any = this.localStorageService.getItem(
      StorageKey.userDetail,
    );
    return !currentUser?.hide_view;
  }


  setClickedRow(i: number, j: number): void {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent(): void {
    this.status = true;
  }
  scrollToTop(): void {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  get theme(): Branding {
    return this.platformService.branding;
  }

  navigateTo() {
    this._router.navigate(['/user/myprofile']);
  }
}
